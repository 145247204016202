import React from 'react'
import Footer from '../../Components/Footer/Footer'
import './Faq.css'
const Faq = () => {
    return (
        <>
            <section className='informationPreviewMain'>
                <div className='titleBox'>
                    <h2>FAQs</h2>
                </div>
                <div className='row tablemainnet'>
                    <div className='col-12 col-md-12 mb-3'>
                        <div className='mainnetInr'>
                            <h3>What is Decentralization?</h3>
                            <p>It is a type of server architecture where the entire code is deployed in a manner that it is never editable once deployed. It uses Blockchain technology & it is highly trustable & stable.</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 mb-3'>
                        <div className='mainnetInr'>
                            <h3>What are Smart Contracts ?</h3>
                            <p>Smart contracts are simply programs stored on a blockchain that run when predetermined conditions are met. They typically are used to automate the execution of an agreement so that all participants can be immediately certain of the outcome, without any intermediary’s involvement or time loss. They can also automate a workflow, triggering the next action when conditions are met.</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 mb-3'>
                        <div className='mainnetInr'>
                            <h3>SmmartFox NFT Smart Contract</h3>
                            <p>SmmartFox NFT smart contract never stores participants’ funds, which means their balance is always zero. Here the purpose of smart contract is to trigger events that involve redirecting funds from incoming transactions to the participant’s wallers.</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 mb-3'>
                        <div className='mainnetInr'>
                            <h3>Referral linking</h3>
                            <p>Once a referral link is created, it can’t be changed manually or through smart contracts as well. Upline & Downline are assigned to you in your structure forever.</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 mb-3'>
                        <div className='mainnetInr'>
                            <h3>Personal Wallet</h3>
                            <p>To become a member, you need to create your personal wallet in Metamask or Trust Wallet app. Only you have access to the funds. All rewards as per the plan are instantly credited to your personal wallet. All transactions are stored in the public domain in a blockchain. You can easily check each transaction and see where the funds have been transferred.</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 mb-3'>
                        <div className='mainnetInr'>
                            <h3>Registration in SmmartFox NFT Plan</h3>
                            <p>Your registration is free in SmmartFoxNFT Pro, as when you buy the NFT in every slots you will get a free user ID registered and you can earn referral earnings.</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 mb-3'>
                        <div className='mainnetInr'>
                            <h3>SmmartFox NFT Plan Explained</h3>
                            <p>- You get 30% payment of the direct referrals (Unlimited Referrals).</p>
                            <p>- 15% Distributed in Royalty Pool, 5% - Base Pool, 5% - Royal Pool and 5% - Elite Pool.</p>
                            <p>- From the 1st line, you get 5%. From each of the 2 places.</p>
                            <p>- From the 2nd line, you get 10%. From each of the 4 places.</p>
                            <p>- From the 3rd line, you get 15%. From each of 8 places.</p>
                            <p>- And from the 4th line, you get 25% from 16 places.</p>
                        </div>
                    </div>

                    <div className='col-12 col-md-12 mb-3'>
                        <div className='mainnetInr'>
                            <h3>SmmartFox NFT - Rebooking</h3>
                            <p>The last 4 payments from 4th line, 25%+25%+25%+25%, are used for repeat and 100% is distributed to upline partners, and a new cycle of the level begins automatically.</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 mb-3'>
                        <div className='mainnetInr'>
                            <h3>How does Rebooking works Automatically?</h3>
                            <p>automatic cycles open the same level for you, and you continue to receive income from it. This happens automatically as soon as the last free spot under you is taken by a partner. The current level completes its cycle, and the next cycle begins. When a cycle is completed, you occupy a free spot in the level of your upline partner. And you have your level reopened with free spots for new partners. For each cycle, a reward of 100% goes to your upline partner's wallet. Cycles work in the same way for your invited partners, and each time their level completes a cycle, you receive a reward for it.
                                Activating several levels and inviting partners to those levels at once speeds up your profit many times. The levels do not have a validity period, so you should not be afraid that they will expire. Each active level constantly brings you income. How many levels can be activated at once? As many as you want, even all at once.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Faq