import React, { useCallback, useEffect, useState } from 'react'
import './Pools.css'
import logofav from '../../Assets/images/iconlogo.webp';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import useActiveWeb3React from '../../hooks/useWeb3';
import { ethers } from 'ethers';
import { useLocation, useNavigate } from 'react-router';
import { BASE_URL, Pool2, Pool3, names } from '../../config';
import copyIcon from '../../Assets/images/copy.png'
import { shortAddress } from '../../utils';
import { useRoyaltyPoolContract, useStakeContract, useUpdateAllPoolContract } from '../../hooks/useContract';
import { Pool1 } from '../../config';

import ClaimABI from '../../abi/smmartfoxClaim.json'
import { showFulfill } from '../../utils/pageHelpers';
import { getSigner } from '../../utils/getContract';
import { formatUnits } from 'ethers/lib/utils';
import { ConstructionOutlined, Pool } from '@mui/icons-material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { NavLink } from 'react-router-dom';
import totalShareIcon from '../../Assets/images/icons/totalShares-icon.webp'
import sharesMatic from '../../Assets/images/icons/sharesMatic-icon.webp'
import shareValue from '../../Assets/images/icons/shareValue-icon.webp'
import PoolMatic from '../../Assets/images/icons/PoolMatic-icon.webp'
import totalpool from '../../Assets/images/icons/totalpool-shares.webp'

function isBetween1And31(number) {
    return number >= 1 && number <= 31;
}

const copySuccessfully = () => toast.success('Copied Successfully!');

function Pools() {

    const [value, setValue] = useState(0);
    console.log(value);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [userId, setUserId] = useState(0);
    const [levelBought, setLevelBought] = useState(0);

    const [PoolAddress, setPoolAddress] = useState(false);
    const [PoolName, setPoolName] = useState();
    const [tag, setTag] = useState("");


    const { library, chainId, account } = useActiveWeb3React()
    const [isInTrn, setTransaction] = useState(false);
    const [userAddress, setUserAddress] = useState("");
    const [poolDetailsState, setPoolDetailsState] = useState([]);
    const [activePool, setActivePool] = useState({});
    const [tableinfo, settableinfo] = useState(0);
    const [tableinfo1, settableinfo1] = useState(0);
    const [isUpdated, setIsUpdated] = useState(false);


    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }

        if (location.pathname === '/ElitePool') {
            setPoolName('Elite Pool')
            setPoolAddress(Pool3)
            setTag("Users with 15th Position Unlocked")
        } else if (location.pathname === '/RoyalPool') {
            setPoolName('Royal Pool')
            setPoolAddress(Pool2)
            setTag("Users with >= 10th and < 15th Position Unlocked")
        }
        else {
            setPoolAddress(Pool1)
            setPoolName('Base Pool')
            setTag("Users with >= 5th and < 10th Position Unlocked")
        }
    }, [account, location])


    const lock = useRoyaltyPoolContract(PoolAddress)
    const planC = useStakeContract();
    const updateC = useUpdateAllPoolContract()

    const fetchPanelDetails = useCallback(async () => {
        let actDiv = document.getElementById('activitiesView');
        actDiv.classList.add('loaderData');
        let _userAddress;
        _userAddress = await planC.getAddressById(userId);
        setUserAddress(_userAddress)
        const userInfoResponse = await planC.userInfos(_userAddress);
        const levelBought = ethers.BigNumber.from(userInfoResponse["levelBought"]).toNumber();
        if (levelBought > 0) {
            if (levelBought > 15) {
                setLevelBought(15)
            } else {
                setLevelBought(levelBought)
            }
        }
        let arr = {
            5: 1,
            6: 2,
            7: 4,
            8: 8,
            9: 16,
            10: 32,
            11: 64,
            12: 128,
            13: 256,
            14: 512,
            15: 1024
        }
        let total = 0
        for (let i = 5; i <= parseInt(levelBought); i++) {
            total += arr[i]
            settableinfo(total)
        }

        let eligibleTotal = 0
        let startI = Pool1 === PoolAddress ? 5 : Pool2 === PoolAddress ? 10 : 15
        let endI = Pool1 === PoolAddress ? 10 : Pool2 === PoolAddress ? 15 : 16

        for (let i = startI; i < endI; i++) {
            if (i > parseInt(levelBought)) break
            eligibleTotal += arr[i]
        }
        settableinfo1(eligibleTotal)

        try {
            const poolitemC = [];
            let pools = await lock.getPoolLength()
            pools = parseInt(pools);


            for (let i = 0; i < pools; i++) {
                // if (i === 0) continue
                let data = {};
                let round = await lock.rounds(i)

                let roundDetails = await lock.poolDetails(round.roundAddress)

                let roundInstance = new ethers.Contract(round.roundAddress, ClaimABI, account ? library.getSigner() : getSigner())

                let totalCollected = await roundInstance.totalCollected()
                let perShare = await roundInstance.getLivePerShareValue()
                let totalShare = await roundInstance.totalShares()
                let endTime = await roundInstance.end()
                let startTime = await roundInstance.start()
                let userShare = await roundInstance.user(_userAddress);


                perShare = ethers.utils.formatUnits(perShare, 6)
                // let updateShare = await roundInstance.updateMyShare()
                // let claim = await roundInstance.claim()
                data.end = new Date(parseInt(endTime) * 1000);
                data.start = new Date(parseInt(startTime) * 1000);
                data.perShare = parseFloat(perShare);
                data.totalShare = parseInt(totalShare);
                data.isActive = roundDetails.isActive;
                data.userShare = (parseInt(userShare.shares));
                data.roundAddress = round.roundAddress;
                data.totalCollected = ethers.utils.formatUnits(totalCollected, 6);
                poolitemC.push(data);

                // console.debug(data)
                if (roundDetails.isActive) {
                    // console.debug(data)
                    setActivePool(data)
                }
            }
            setPoolDetailsState(poolitemC.reverse());
            actDiv.classList.remove('loaderData');
        } catch (err) {
            console.debug(err)
        }

    }, [userId, lock, planC, PoolAddress, library, account])

    useEffect(() => {
        const activePooluserShare = activePool.userShare ?? "0"
        const eligibleShares = tableinfo1

        console.debug(activePooluserShare, eligibleShares)

        if (parseInt(activePooluserShare) !== eligibleShares) {
            setIsUpdated(true)
        } else {
            setIsUpdated(false)
        }
    }, [activePool, tableinfo1])
    const navigate = useNavigate();

    async function poolOverView(roundAddress) {
        if (Pool1 === PoolAddress) {
            navigate("/pool-overview?user_id=" + userId + "&round=" + roundAddress + "&pool=1");
        }
        if (Pool2 === PoolAddress) {
            navigate("/pool-overview?user_id=" + userId + "&round=" + roundAddress + "&pool=2");
        }
        if (Pool3 === PoolAddress) {
            navigate("/pool-overview?user_id=" + userId + "&round=" + roundAddress + "&pool=3");
        }


    }

    async function poolClaim(roundAddress) {
        let roundInstance = new ethers.Contract(roundAddress, ClaimABI, account ? library.getSigner() : getSigner())
        let txn = await roundInstance.claim()
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        await txn.wait()
        showFulfill(`Claim Sucessfully`, 'Transaction Confirmed')
    }

    // console.log(activePool.userShare ?? "0")

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }


    useEffect(() => {
        if (userId !== 0) {
            fetchPanelDetails();
        }
    }, [userId, fetchPanelDetails])

    useEffect(() => {
        const inter = setInterval(() => {
            if (userId !== 0) {
                fetchPanelDetails();
            }
        }, 12000)
        return () => {
            clearInterval(inter)
        }
    }, [userId, fetchPanelDetails])


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
        const v = queryParams.get('value');
        if (v) {
            setValue(Number(v));
        }
    }, [setUserId, setValue, location])

    const updateAllInOne = useCallback(async () => {
        if (isInTrn) return
        setTransaction(true)
        try {
            const txn = await updateC.updateShares()
            showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
            await txn.wait()
            showFulfill(`Update All In One Sucessfully`, 'Transaction Confirmed')
        } catch (err) {
            console.debug(err)
        }
        setTransaction(false)
    })

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                className='tabPools'
                aria-label="scrollable force tabs example"
            >

                <NavLink to={`/BasePool?user_id=${userId}&value=0`} activeClassName="active" className={'am_btn'}>
                    <Tab label="Base Pool" value={0} />
                </NavLink >
                <NavLink to={`/RoyalPool?user_id=${userId}&value=1`} activeClassName="active" className={'am_btn'}>
                    <Tab label="Royal Pool" value={1} />
                </NavLink >
                <NavLink to={`/ElitePool?user_id=${userId}&value=2`} activeClassName="active" className={'am_btn'}>
                    <Tab label="Elite Pool" value={2} />
                </NavLink >
            </Tabs>
            <section className='dashboardPreviewMain dashboardMian'>
                <div className='d-flex  flex-column align-items-center poolhead'>
                    <h3 className='poolHeading m-0'>{PoolName || "Pool"}</h3>
                    <p className='tagline m-0'>{tag}</p>
                </div>

                <div className='row tablemainnet'>
                    <div className='col-12 col-md-12 order-1 order-md-1 mb-40'>
                        <div className='row justify-content-center'>
                            {/* <div className='col-12 col-sm-6 col-lg-6 col-xl-4 mb-4'>
                                <div className='inrBox poolsflexbox'>
                                    <img src={totalpool} alt="Pool Mati" />
                                    <h3>--</h3>
                                    <p>Total Pool Shares</p>
                                </div>
                            </div> */}
                            <div className='col-12 col-sm-6 col-lg-6 col-xl-3 mb-4'>
                                <div className='inrBox poolsflexbox'>
                                    <img src={totalShareIcon} alt="Total Shares" />
                                    <h3>{activePool.totalShare ?? 0}</h3>
                                    <p>Total Pool Shares</p>
                                </div>
                            </div>

                            <div className='col-12 col-sm-6 col-lg-6 col-xl-3 mb-4'>
                                <div className='inrBox poolsflexbox'>
                                    <img src={sharesMatic} alt="Shares Matic" />
                                    <h3>{(parseFloat(activePool.userShare ?? "0") * parseFloat(activePool.perShare ?? "0")).toFixed(4)}</h3>
                                    <p>Your Share Value (USDT)</p>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-lg-6 col-xl-3 mb-4'>
                                <div className='inrBox poolsflexbox'>
                                    <img src={shareValue} alt="Share Value" />
                                    <h3>{Number((parseFloat(activePool.perShare ?? "0") ?? 0).toFixed(4))}</h3>
                                    <p>Per Share Value</p>
                                </div>
                            </div>
                            <div className='col-12 col-sm-6 col-lg-6 col-xl-3 mb-4'>
                                <div className='inrBox poolsflexbox'>
                                    <img src={PoolMatic} alt="Pool Mati" />
                                    <h3>{activePool.totalCollected ?? 0}</h3>
                                    <p>Pool USDT</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-12 order-0 order-md-0 progileDashBx'>
                        <div class="profilebox">
                            <div class="imgboxprof">
                                <img className="prfllgo" src={logofav} style={{ borderRadius: "5px" }} alt="ox profile" />
                            </div>
                            <h2>ID #{userId}</h2>
                            <h3>Rank: <b>{names[levelBought - 1] ?? ""}</b></h3>
                            <h4>Available Shares: <b>{tableinfo}</b></h4>
                            <h4>Eligible Shares For this Pool: <b>{tableinfo1}</b></h4>
                            <CopyToClipboard text={userAddress} className="request">
                                <p>{userAddress.substr(0, 4)}...{userAddress.substr(-4)}
                                    <svg onClick={() => { copySuccessfully(); }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="icon" role="img"><polygon fill="var(--ci-primary-color, currentColor)" points="408 432 376 432 376 464 112 464 112 136 144 136 144 104 80 104 80 496 408 496 408 432" class="ci-primary"></polygon><path fill="var(--ci-primary-color, currentColor)" d="M176,16V400H496V153.373L358.627,16ZM464,368H208V48H312V200H464Zm0-200H344V48h1.372L464,166.627Z" class="ci-primary"></path></svg>
                                </p>
                            </CopyToClipboard>

                            {isUpdated && <button
                                className='am_btn mt-4'
                                onClick={() => {
                                    updateAllInOne()
                                    scrollToTop();
                                }}
                            >
                                Update Shares
                            </button>}
                        </div>
                    </div>
                </div>
            </section>
            <section className='partnersPreviewMain'>
                <div className='titleBox' style={{ marginBottom: '0' }}>
                    <h2 style={{ marginBottom: '0' }}>{PoolName} History</h2>
                </div>
                <div className='row tablemainnet'>
                    <div className="tablearea">
                        <div className='tableInr' id='activitiesView'>
                            <div className='table-responsive'>
                                <table className="table table-striped-columns">
                                    <thead>
                                        <tr className='headSec'>
                                            <th width="">Start</th>
                                            <th width="" className='removebg'>End</th>
                                            <th width="" className='removebg'>Total Collected</th>
                                            <th width="">Per Share</th>
                                            <th width="">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {poolDetailsState.map((a, index) => {
                                            // if (index === 0) {
                                            //     return null; // Skip rendering this element
                                            // }
                                            return (
                                                <tr key={index} className='oddeven'>
                                                    <td>{a.start.toLocaleString()}</td>
                                                    <td>{a.end.toLocaleString()}</td>
                                                    <td>{a.totalCollected} USDT</td>
                                                    <td>{Number(parseFloat(a.perShare ?? "0").toFixed(6))} USDT</td>
                                                    <td className=''>
                                                        {!a.isActive ? (
                                                            <button
                                                                className='am_btn'
                                                                // disabled={true}
                                                                onClick={() => { poolClaim(a.roundAddress); }}
                                                            >
                                                                Claim
                                                            </button>
                                                        ) : "-"}
                                                    </td>
                                                </tr>
                                            );
                                        })}


                                        {poolDetailsState.length == 0 ? <></> : ''}
                                    </tbody>
                                </table>

                            </div>
                            {/* <div className='d-flex align-items-center justify-content-end mt-4 pagination'>
                                    <CPagination aria-label="Page navigation example">
                                        <CPaginationItem>Previous</CPaginationItem>
                                        <CPaginationItem>1</CPaginationItem>
                                        <CPaginationItem>2</CPaginationItem>
                                        <CPaginationItem>3</CPaginationItem>
                                        <CPaginationItem>Next</CPaginationItem>
                                    </CPagination>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pools