import React, { useCallback, useEffect, useState } from 'react'
import './Treeviewdetail.css'
import logofav from '../../Assets/images/iconlogo.webp'
import tether from '../../Assets/images/tether.png'
import ocicon from '../../Assets/images/iconlogo.webp'
import Footer from '../../Components/Footer/Footer'
import useActiveWeb3React from '../../hooks/useWeb3'
import { useStakeContract } from '../../hooks/useContract'
import { useLocation, useNavigate } from 'react-router'
import { ethers } from 'ethers'
import getLast10TransactionsAndEvents from '../../services/activities'
import { levelPrices } from '../../config'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react'
import defaultprofile from '../../Assets/images/iconlogo.webp'

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import treeViewActivities from '../../services/treeViewActivities'
import { shortAddress } from '../../utils'
import income24Icon from '../../Assets/images/icons/income24-icon.webp'
import incomeIcon from '../../Assets/images/icons/income-icon.webp'

import { IKImage } from 'imagekitio-react';
const urlEndpoint = 'https://ik.imagekit.io/360kit/SmmartFox';


function Treeviewdetail() {
    const { library, chainId, account } = useActiveWeb3React()
    const [isInTrn, setTransaction] = useState(false);
    const [userId, setUserId] = useState();
    const [slot, setSlot] = useState(1);
    const [activity, setActivity] = useState([]);
    const [reinvestEle, setReinvestEle] = useState([]);

    const [partners, setPartners] = useState(0);
    const [reinvestNo, setReinvestNo] = useState(null);
    const [currentParent, setCurrentParent] = useState(0);
    const [childTransactions, setChildTransactions] = useState([]);
    const [levelIncome, setLevelIncome] = useState(0);
    const [directIncome, setDirectIncome] = useState(0);
    const treeNodes = 31;

    const totalLevels = 15;
    let tempidsArray = new Array(31).fill(0);
    const [idsArray, setIdsArray] = useState([]);
    const transactionTypes = [
        { name: 'directPartner', class: 'whiteColor' },
        { name: 'spillOverAbove', class: 'blueColor' },
        { name: 'spillOverBelow', class: 'yellowColor' },
        { name: 'freeFlow', class: 'greenColor' }
    ]

    const [reinvestCounts, setReinvestCounts] = useState(useState(Array(15).fill(0)));

    const planC = useStakeContract();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        const _slot = queryParams.get('slot');
        const cycle = queryParams.get('cycle');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
        if (queryParams.get('slot')) {
            setSlot(_slot)
        }
        if (queryParams.get('cycle')) {
            // console.log(cycle)
            setReinvestNo(cycle)
        }
    }, [setUserId, setReinvestNo, setSlot, location])

    useEffect(() => {
        if (planC) {
            try {
                // console.log(reinvestNo, userId, slot)
                fetchReinvests();
                fetchDetailsForReinvest()
            } catch (Err) {
                console.log('err', Err)
            }
        }
    }, [planC, reinvestNo, userId, slot])

    useEffect(() => {
        if (planC) {
            fetchPanelDetails();
            getPartnersForSlot();
        }
    }, [planC, reinvestEle, userId, slot])

    const fetchReinvests = useCallback(() => {
        (async () => {
            if (userId) {
                let userAddress = await planC.getAddressById(userId);
                const tempreinvestCounts = await getReinvestCounts(userAddress);
                setReinvestCounts(tempreinvestCounts)
                // console.log(tempreinvestCounts)
            }
        })()
    }, [userId, planC])

    const fetchDetailsForReinvest = useCallback(async () => {

        if (!userId) return
        let latestReinvest;

        let userAddress = await planC.getAddressById(userId);
        let totalReinvest = ethers.BigNumber.from(await planC.userReInvestNumbers(userAddress, slot)).toNumber();
        // console.log("totalReinvest", totalReinvest)

        const activeGoldInfoResponse = await planC.activeGoldInfos(userAddress, slot);
        const tempcurrentParrent = ethers.BigNumber.from(activeGoldInfoResponse["currentParent"]).toNumber();

        setCurrentParent(tempcurrentParrent);

        // if ( totalReinvest === 0 ) { return }

        if (reinvestNo !== null) {
            latestReinvest = reinvestNo;
            // console.log('this called')
        } else {
            // console.log('why this called', reinvestNo)
            latestReinvest = totalReinvest
            // setReinvestNo(latestReinvest);
        }

        const elements = [];

        for (let i = 0; i < totalReinvest; i++) {
            elements.push(i);
        }
        setReinvestEle(elements)


        // if (parseInt(userId) === 1 && parseInt(latestReinvest) >= 1) {
        //     latestReinvest = parseInt(latestReinvest) + 1 > parseInt(totalReinvest) ? totalReinvest : parseInt(latestReinvest) + 1
        // }

        let idsResponse = await planC.getAllLevelTrees(userAddress);
        if (parseInt(totalReinvest) !== 0) {
            idsResponse = await planC.getTreeWithReinvestOfRoot(userAddress, slot, latestReinvest);
            // console.log("using tree reinvest count")
            // console.log(idsResponse)
        } else {
            let userWithLevels = new Array(totalLevels).fill(0).map(() => new Array(treeNodes).fill(0));
            // idsResponse = idsResponse[slot]
            // console.log(`fetching from ${slot} ${userAddress}`)
            // console.log(idsResponse)
            for (let i = 0; i < 15; i++) {
                if (idsResponse.tree) {
                    idsResponse.tree[i].forEach((response, index) => {
                        const id = ethers.BigNumber.from(response).toNumber();
                        if (userWithLevels[i]) userWithLevels[index] = id;
                    });
                } else {
                    idsResponse[i].forEach((response, index) => {
                        const id = ethers.BigNumber.from(response).toNumber();
                        if (userWithLevels[i]) userWithLevels[i][index] = id;
                    });
                }
            }

            idsResponse = userWithLevels[slot - 1]
        }



        const userWithLevel = [];
        idsResponse.tree ? idsResponse.tree.forEach((response, index) => {
            const id = ethers.BigNumber.from(response).toNumber();
            userWithLevel[index] = id;
        })
            : idsResponse.forEach((response, index) => {
                const id = ethers.BigNumber.from(response).toNumber();
                userWithLevel[index] = id;
            });

        // console.log(idsResponse)
        setIdsArray(userWithLevel);
    }, [userId, planC, reinvestNo, slot])

    const getPartnersForSlot = useCallback(async () => {
        if (!userId) return

        let userAddress = await planC.getAddressById(userId);
        let totalReinvest = ethers.BigNumber.from(await planC.userReInvestNumbers(userAddress, slot)).toNumber();

        let countP = 0;
        for (let i = 0; i <= totalReinvest; i++) {

            // console.warn("transactions->i", i);
            const transactionTypsesResponse = await planC.getChildrenTransactionTypes(userAddress, slot, i);

            let transactions = [];
            transactionTypsesResponse.transactionTypes ? transactionTypsesResponse.transactionTypes.forEach((t) => {
                const transaction = { userId: ethers.BigNumber.from(t["userId"]).toNumber(), type: t["transactionType"] }
                transaction.typeProperties = transactionTypes[transaction.type];
                transactions.push(transaction);
            })
                : transactionTypsesResponse.forEach((t) => {
                    const transaction = { userId: ethers.BigNumber.from(t["userId"]).toNumber(), type: t["transactionType"] }
                    transaction.typeProperties = transactionTypes[transaction.type];
                    transactions.push(transaction);
                })

            // console.warn("transactions", i, transactions);
            transactions.forEach((t) => {
                if (t.typeProperties.name == "directPartner") {
                    countP++;
                }
            });
        }

        // console.warn("totalPartners ->", countP);
        setPartners(countP);

    }, [userId, slot, reinvestNo, planC, reinvestEle])

    const fetchPanelDetails = useCallback(async () => {
        if (!userId) return
        // console.log(userId)
        let actDiv = document.getElementById('activitiesView');
        let treeViewMain = document.getElementById('treeViewMain');
        actDiv.classList.add('loaderData');

        let userAddress = await planC.getAddressById(userId);

        // console.log(reinvestEle)
        let reinvestCount = reinvestNo
        if (!reinvestNo) {
            reinvestCount = reinvestEle.length > 0 ? reinvestEle.length - 1 : 0
        }
        // if (parseInt(userId) === 1 && parseInt(reinvestCount) >= 1) {
        //     reinvestCount = parseInt(reinvestCount) + 1
        // }
        // console.log(reinvestCount)
        const transactionTypsesResponse = await planC.getChildrenTransactionTypes(userAddress, slot, reinvestCount);

        let transactions = [];
        transactionTypsesResponse.transactionTypes ? transactionTypsesResponse.transactionTypes.forEach((t) => {
            const transaction = { userId: ethers.BigNumber.from(t["userId"]).toNumber(), type: t["transactionType"] }
            transaction.typeProperties = transactionTypes[transaction.type];
            transactions.push(transaction);
        })
            : transactionTypsesResponse.forEach((t) => {
                const transaction = { userId: ethers.BigNumber.from(t["userId"]).toNumber(), type: t["transactionType"] }
                transaction.typeProperties = transactionTypes[transaction.type];
                transactions.push(transaction);
            })

        setChildTransactions(transactions);
        let countP = 0;
        transactions.forEach((t) => {
            if (t.typeProperties.name == "directPartner") {
                countP++;
            }
        });

        // setPartners(countP);

        // setPartners(partners)

        // console.log(userId, slot, reinvestNo)
        treeViewMain.classList.remove('loaderData');


        // let tMs = await planC.lastIDCount();
        // setTotalMembers(ethers.BigNumber.from(tMs).toNumber());
        // let totalInvestedBN = await planC.totalValueInvested();
        // let allTotalValue = ethers.utils.formatEther(totalInvestedBN)
        // setTotalInvested(allTotalValue);

        let getActivity = await getLast10TransactionsAndEvents(library, userId, slot);
        console.log(getActivity)
        setActivity(getActivity ?? [])
        actDiv.classList.remove('loaderData');
    }, [userId, slot, reinvestNo, planC, reinvestEle])

    const counts = []
    const getReinvestCounts = useCallback(async (_user) => {
        for (let i = 1; i <= 15; i++) {
            let count = await planC.userReInvestNumbers(_user, i);
            // console.log(count)
            counts[i - 1] = parseInt(count);
        }
        return counts;
    }, [planC])

    const navigateSlot = useCallback(async (_slot) => {
        let treeViewMain = document.getElementById('treeViewMain');
        if (slot !== slot) treeViewMain.classList.add('loaderData');
        setSlot(_slot)
        navigate('/dashboard-user?user_id=' + userId + '&slot=' + _slot)
    }, [userId])

    const navigateCycle = useCallback(async (_reinvestNo) => {
        let treeViewMain = document.getElementById('treeViewMain');
        if ( _reinvestNo === 'current' ) {
            treeViewMain.classList.add('loaderData');
            setReinvestNo(null)
            navigate('/dashboard-user?user_id=' + userId + '&slot=' + slot)
            return;
        }
        if (reinvestNo !== _reinvestNo) treeViewMain.classList.add('loaderData');
        setReinvestNo(_reinvestNo)
        navigate('/dashboard-user?user_id=' + userId + '&slot=' + slot + '&cycle=' + _reinvestNo)
    }, [userId, slot])

    const redirectUserTree = useCallback(async (_userId) => {
        let treeViewMain = document.getElementById('treeViewMain');
        if (userId !== _userId) treeViewMain.classList.add('loaderData');
        setUserId(_userId)
        navigate('/dashboard-user?user_id=' + _userId + '&slot=' + slot);
    }, [setUserId, slot])

    const getIdClass = useCallback((id) => {
        if (id == 0) {
            return "greyColor";
        } else {
            const txType = childTransactions.find(t => t.userId == id);
            return (txType) ? txType.typeProperties.class : "yellowColor";
        }
    }, [childTransactions]);

    const [startDate1, setStartDate1] = useState(new Date('2023-03-01'));
    const [startDate2, setStartDate2] = useState(new Date());
    const [filterSlotFrom, setFilterSlotFrom] = useState(1);
    const [filterSlotTo, setFilterSlotTo] = useState(12);

    const optionsSlot1 = [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
    ];
    const optionsSlot2 = [
        { value: 1, label: "1" },
        { value: 2, label: "2" },
        { value: 3, label: "3" },
        { value: 4, label: "4" },
        { value: 5, label: "5" },
        { value: 6, label: "6" },
        { value: 7, label: "7" },
        { value: 8, label: "8" },
        { value: 9, label: "9" },
        { value: 10, label: "10" },
        { value: 11, label: "11" },
        { value: 12, label: "12" },
    ];


    const filterActivities = useCallback(async () => {
        let filterStartDate = new Date(startDate1);
        let filterSD = Math.floor(filterStartDate.getTime() / 1000)
        let filterEndDate = new Date(startDate2);
        let filterED = Math.floor(filterEndDate.getTime() / 1000);

        let dataTree = await treeViewActivities(userId, filterSD, filterED, filterSlotFrom, filterSlotTo);
        console.log(dataTree);
        setDirectIncome(dataTree.sumDirectPaidEvents)
        setLevelIncome(dataTree.sumTreePayoutEvents)
        setActivity(dataTree.mergedArray)
    })

    return (
        <>
            <section className="treeAeromatix mt-5">
                <div className='titleBox'>
                    <h2>TreeView Detail</h2>
                    {/* <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p> */}
                </div>
                {(parseInt(currentParent) !== parseInt(userId)) && <div className='titleBox'>
                    <h2 onClick={() => redirectUserTree(currentParent)} style={{ textAlign: "center", cursor: "pointer" }}>Upline <span style={{ color: "#017dbd" }}>{currentParent}</span></h2>
                </div>}
                <div className="row rowtreebox treeBoxSingleView">
                    {slot != 1 ? <div className="sidepnl mb-4" onClick={() => { navigateSlot(parseInt(slot) - 1) }}>Slot {parseInt(slot) - 1}</div> : <div className="sidepnl disableBx mb-4"></div>}
                    <div className="treeBoxSingleInr mb-4">
                        <div className="treeboxDetail">
                            <div className="row mb-4 topheadbx">
                                <div className="col-4">Slot {slot}</div>
                                <div className='col-4'>
                                    <h2 onClick={() => redirectUserTree(userId)} style={{ textAlign: "center", cursor: "pointer" }}>{userId}</h2>
                                </div>
                                <div className="col-4">
                                    <div className="headeline"><img src={ocicon} alt="USDT Logo" width="20" height="20" /><span>${levelPrices[parseInt(slot) - 1]}</span></div>
                                </div>
                            </div>
                            <div className="itemsmain loaderData" id='treeViewMain'>
                                <div className="col6">
                                    <div className="col12"><span title={idsArray[1]} onClick={() => { redirectUserTree(idsArray[1]) }} className={getIdClass(idsArray[1])}>{idsArray[1] > 0 ? idsArray[1] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                    <div className="col6">
                                        <div className="col12"><span title={idsArray[3]} onClick={() => { redirectUserTree(idsArray[3]) }} className={getIdClass(idsArray[3])}>{idsArray[3] > 0 ? idsArray[3] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        <div className="col6">
                                            <div className="col12"><span title={idsArray[7]} onClick={() => { redirectUserTree(idsArray[7]) }} className={getIdClass(idsArray[7])}>{idsArray[7] > 0 ? idsArray[7] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[15]} onClick={() => { redirectUserTree(idsArray[15]) }} className={getIdClass(idsArray[15])}>{idsArray[15] > 0 ? idsArray[15] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[16]} onClick={() => { redirectUserTree(idsArray[16]) }} className={getIdClass(idsArray[16])}>{idsArray[16] > 0 ? idsArray[16] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        </div>
                                        <div className="col6">
                                            <div className="col12"><span title={idsArray[8]} onClick={() => { redirectUserTree(idsArray[8]) }} className={getIdClass(idsArray[8])}>{idsArray[8] > 0 ? idsArray[8] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[17]} onClick={() => { redirectUserTree(idsArray[17]) }} className={getIdClass(idsArray[17])}>{idsArray[17] > 0 ? idsArray[17] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[18]} onClick={() => { redirectUserTree(idsArray[18]) }} className={getIdClass(idsArray[18])}>{idsArray[18] > 0 ? idsArray[18] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        </div>
                                    </div>
                                    <div className="col6">
                                        <div className="col12"><span title={idsArray[4]} onClick={() => { redirectUserTree(idsArray[4]) }} className={getIdClass(idsArray[4])}>{idsArray[4] > 0 ? idsArray[4] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        <div className="col6">
                                            <div className="col12"><span title={idsArray[9]} onClick={() => { redirectUserTree(idsArray[9]) }} className={getIdClass(idsArray[9])}>{idsArray[9] > 0 ? idsArray[9] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[19]} onClick={() => { redirectUserTree(idsArray[19]) }} className={getIdClass(idsArray[19])}>{idsArray[19] > 0 ? idsArray[19] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[20]} onClick={() => { redirectUserTree(idsArray[20]) }} className={getIdClass(idsArray[20])}>{idsArray[20] > 0 ? idsArray[20] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        </div>
                                        <div className="col6">
                                            <div className="col12"><span title={idsArray[10]} onClick={() => { redirectUserTree(idsArray[10]) }} className={getIdClass(idsArray[10])}>{idsArray[10] > 0 ? idsArray[10] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[21]} onClick={() => { redirectUserTree(idsArray[21]) }} className={getIdClass(idsArray[21])}>{idsArray[21] > 0 ? idsArray[21] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[22]} onClick={() => { redirectUserTree(idsArray[22]) }} className={getIdClass(idsArray[22])}>{idsArray[22] > 0 ? idsArray[22] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col6">
                                    <div className="col12"><span title={idsArray[2]} onClick={() => { redirectUserTree(idsArray[2]) }} className={getIdClass(idsArray[2])}>{idsArray[2] > 0 ? idsArray[2] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                    <div className="col6">
                                        <div className="col12"><span title={idsArray[5]} onClick={() => { redirectUserTree(idsArray[5]) }} className={getIdClass(idsArray[5])}>{idsArray[5] > 0 ? idsArray[5] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        <div className="col6">
                                            <div className="col12"><span title={idsArray[11]} onClick={() => { redirectUserTree(idsArray[11]) }} className={getIdClass(idsArray[11])}>{idsArray[11] > 0 ? idsArray[11] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[23]} onClick={() => { redirectUserTree(idsArray[23]) }} className={getIdClass(idsArray[23])}>{idsArray[23] > 0 ? idsArray[23] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[24]} onClick={() => { redirectUserTree(idsArray[24]) }} className={getIdClass(idsArray[24])}>{idsArray[24] > 0 ? idsArray[24] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        </div>
                                        <div className="col6">
                                            <div className="col12"><span title={idsArray[12]} onClick={() => { redirectUserTree(idsArray[12]) }} className={getIdClass(idsArray[12])}>{idsArray[12] > 0 ? idsArray[12] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[25]} onClick={() => { redirectUserTree(idsArray[25]) }} className={getIdClass(idsArray[25])}>{idsArray[25] > 0 ? idsArray[25] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[26]} onClick={() => { redirectUserTree(idsArray[26]) }} className={getIdClass(idsArray[26])}>{idsArray[26] > 0 ? idsArray[26] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        </div>
                                    </div>
                                    <div className="col6">
                                        <div className="col12"><span title={idsArray[6]} onClick={() => { redirectUserTree(idsArray[6]) }} className={getIdClass(idsArray[6])}>{idsArray[6] > 0 ? idsArray[6] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        <div className="col6">
                                            <div className="col12"><span title={idsArray[13]} onClick={() => { redirectUserTree(idsArray[13]) }} className={getIdClass(idsArray[13])}>{idsArray[13] > 0 ? idsArray[13] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[27]} onClick={() => { redirectUserTree(idsArray[27]) }} className={getIdClass(idsArray[27])}>{idsArray[27] > 0 ? idsArray[27] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[28]} onClick={() => { redirectUserTree(idsArray[28]) }} className={getIdClass(idsArray[28])}>{idsArray[28] > 0 ? idsArray[28] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        </div>
                                        <div className="col6">
                                            <div className="col12"><span title={idsArray[14]} onClick={() => { redirectUserTree(idsArray[14]) }} className={getIdClass(idsArray[14])}>{idsArray[14] > 0 ? idsArray[14] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[29]} onClick={() => { redirectUserTree(idsArray[29]) }} className={getIdClass(idsArray[29])}>{idsArray[29] > 0 ? idsArray[29] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                            <div className="col6"><span title={idsArray[30]} onClick={() => { redirectUserTree(idsArray[30]) }} className={getIdClass(idsArray[30])}>{idsArray[30] > 0 ? idsArray[30] : <img src={defaultprofile} alt="Default Profile" />}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row mt-3 downSticky">
                                <div className="col-12 row footerBox">
                                    <div className="footerlineone">
                                        <svg className="w-5 h-5 stroke-current text-white-500" viewBox="0 0 16 16" fill="none" stroke="#fff" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567" strokeWidth="1.333" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </svg>
                                        <span>{partners}</span>
                                    </div>
                                    <div className="footerline">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="icon" role="img">
                                                <path fill="var(--ci-primary-color, currentColor)" d="M265.614,206.387H456V16H424V149.887L397.863,123.75c-79.539-79.539-208.96-79.54-288.5,0s-79.539,208.96,0,288.5a204.232,204.232,0,0,0,288.5,0l-22.627-22.627c-67.063,67.063-176.182,67.063-243.244,0s-67.063-176.183,0-243.246,176.182-67.063,243.245,0l28.01,28.01H265.614Z" className="ci-primary"></path>
                                            </svg>
                                            <span>{parseInt(reinvestCounts[slot-1])}</span>
                                            {/* {parseInt(userId) === 1 ? reinvestCounts[slot] - 1 : reinvestCounts[slot]} */}
                                        </div>
                                        {/* <div>
                                                <svg className="stroke-current text-white w-6 h-6" viewBox="0 0 20 20" fill="none" stroke="#fff" xmlns="http://www.w3.org/2000/svg">
                                                    <g opacity=".5" strokeLinecap="round" strokeLinejoin="round">
                                                        <path d="M14.167 6.667v-2.5a.833.833 0 0 0-.834-.834H5A1.667 1.667 0 0 0 3.333 5m0 0A1.667 1.667 0 0 0 5 6.667h10a.833.833 0 0 1 .833.833V10m-12.5-5v10A1.667 1.667 0 0 0 5 16.667h10a.833.833 0 0 0 .833-.834v-2.5"></path>
                                                        <path d="M16.667 10v3.333h-3.334a1.667 1.667 0 0 1 0-3.333h3.334Z"></path>
                                                    </g>
                                                </svg>
                                                <span>0</span>
                                            </div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="sidepnl mb-4" onClick={() => { navigateSlot(parseInt(slot) + 1) }}>Slot {parseInt(slot) + 1}</div>
                </div>
                <div className='row dropdownBxCycle'>
                    {reinvestCounts[slot-1] > 0 ? <CDropdown>
                        <CDropdownToggle color="secondary">Reinvest Cycle ( {( (reinvestNo ? (parseInt(reinvestNo)+1): "Current") ) ?? (reinvestEle.length > 0 ? reinvestEle.length : '-')} )</CDropdownToggle>
                        <CDropdownMenu>
                            {reinvestEle.map((item, index) => (
                                <CDropdownItem active={( parseInt(reinvestNo) === parseInt(item))} selected={( parseInt(reinvestNo) === parseInt(item))} key={index} onClick={() => { navigateCycle(item) }}>{item+1}</CDropdownItem>
                            ))}
                            <CDropdownItem active={!reinvestNo} key={`currentTree`} onClick={() => { navigateCycle('current') }}>Current Tree</CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown> : ''}
                </div>
                <div className='treeColorGrp'>
                    <ul>
                        <li><span className='whiteColor'>Direct partner</span></li>
                        <li><span className='blueColor'>Spillover from above</span></li>
                        <li><span className='yellowColor'>Spillover from below</span></li>
                        <li><span className='greenColor'>Gift</span></li>
                        <li><span className='remDot'><svg className="w-5 h-5 mr-2.5 stroke-current text-white" viewBox="0 0 16 16" fill="none" stroke="#fff" xmlns="http://www.w3.org/2000/svg"><path d="M6 7.333A2.667 2.667 0 1 0 6 2a2.667 2.667 0 0 0 0 5.333ZM2 14v-1.333A2.667 2.667 0 0 1 4.667 10h2.666A2.667 2.667 0 0 1 10 12.667V14M10.667 2.086a2.667 2.667 0 0 1 0 5.167M14 14v-1.333a2.667 2.667 0 0 0-2-2.567" stroke-width="1.333" stroke-linecap="round" stroke-linejoin="round"></path></svg>Partners on Slot</span></li>
                        <li><span className='remDot'><svg className="w-5 h-5 mr-2.5 stroke-current text-white" viewBox="0 0 20 20" fill="none" stroke="#2CFF4E" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M6.354 3.818a7.25 7.25 0 0 1 10.808 5.28.5.5 0 1 1-.99.137A6.25 6.25 0 0 0 4.551 7h2.115a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5V4.167a.5.5 0 1 1 1 0v2.086a7.25 7.25 0 0 1 2.521-2.435ZM3.265 10.338a.5.5 0 0 1 .564.427A6.25 6.25 0 0 0 15.449 13h-2.116a.5.5 0 1 1 0-1H16.667a.5.5 0 0 1 .5.5v3.333a.5.5 0 1 1-1 0v-2.086a7.25 7.25 0 0 1-13.329-2.845.5.5 0 0 1 .427-.564Z"></path></svg>Slot Cycle</span></li>
                    </ul>
                </div>
            </section>
            {/* <section className='dateFormSection'>
                <div className='row dateFormInr'>
                    <div className='col-6 col-sm-3'>
                        <div className='inrBox'>
                            <p>Date From</p>
                            <DatePicker selected={startDate1} onChange={(date) => setStartDate1(date)} />
                        </div>
                    </div>
                    <div className='col-6 col-sm-3'>
                        <div className='inrBox'>
                            <p>Date To</p>
                            <DatePicker selected={startDate2} onChange={(date) => setStartDate2(date)} />
                        </div>
                    </div>
                    <div className='col-6 col-sm-3'>
                        <div className='inrBox'>
                            <p>Slot From</p>
                            <Select options={optionsSlot1} defaultValue={{ label: "1", value: 1 }} onChange={(selectedOption) => setFilterSlotFrom(selectedOption.value)} />
                        </div>
                    </div>
                    <div className='col-6 col-sm-3'>
                        <div className='inrBox'>
                            <p>Slot To</p>
                            <Select options={optionsSlot2} defaultValue={{ label: "12", value: 12 }} onChange={(selectedOption) => setFilterSlotTo(selectedOption.value)} />
                        </div>
                    </div>
                    <div className='inrBoxSubmitBtn' onClick={filterActivities}>
                        <button>Submit</button>
                    </div>
                </div>
            </section> */}
            {/* <section className='dashboardMian'>
                <div className='titleBox'>
                    <h2>SmmartFox NFT Activities</h2>
                </div>
                <div className='row'>
                    <div className='col-6 col-md-6'>
                        <div className='inrBox'>
                            <p>Direct Income</p>
                            <h3>{directIncome}</h3>
                            <img src={income24Icon} alt="Direct Income" />
                        </div>
                    </div>
                    <div className='col-6 col-md-6'>
                        <div className='inrBox'>
                            <p>Total Level Income</p>
                            <h3>{levelIncome}</h3>
                            <img src={incomeIcon} alt="Total Level Income" />
                        </div>
                    </div>
                </div>
            </section> */}
            <section className='statsPreviewMain dashUserTbl'>
                <div className='row tablemainnet'>
                    <div className='col-12 col-md-12'>
                        <div className="titleBox">
                            <h2>Earnings</h2>
                        </div>
                        <div className='tableInr' id='activitiesView'>
                            <div className='table-responsive'>
                                <table className="table table-striped-columns">
                                    <thead>
                                        <tr className='headSec'>
                                            <th></th>
                                            <th >Date</th>
                                            <th>ID</th>
                                            <th>Earnings</th>
                                            <th className='text-center'>Txn</th>
                                            <th className='text-end pe-4'>Remark</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {activity.map((a, index) => {
                                            // const humanReadableDate = new Date(a.blockTimestamp * 1000).toLocaleString();
                                            return (<tr key={index}>
                                                <td><svg className="fill-current text-yellow h-18px w-18px " viewBox="0 0 20 20" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="M6 11.5c-.867.49-1.735 1.394-2.18 2.285a5 5 0 0 0-.487 2.882 5 5 0 0 0 2.882-.486C7.106 15.735 8.01 14.867 8.5 14" fill-opacity=".5"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7.26 12.74a6.667 6.667 0 0 0-3.927-1.906 5 5 0 0 1 4.167-2.5 7.5 7.5 0 0 1 6.667-5 2.5 2.5 0 0 1 2.5 2.5 7.5 7.5 0 0 1-5 6.666 5.001 5.001 0 0 1-2.5 4.167 6.667 6.667 0 0 0-1.906-3.928Zm6.073-5.24a.833.833 0 1 1-1.666 0 .833.833 0 0 1 1.666 0Z"></path></svg></td>
                                                <td className='linkshr'>
                                                    <span>{a.date}</span></td>
                                                <td><a href={`/dashboard?user_id=${a.userId}`} target='_blank'>{parseInt(a.userId)}</a></td>
                                                <td className='txtinf'><span>+</span>{ethers.utils.formatEther(a.amount ?? "0")} USD</td>
                                                <td className='linkshr rmbg'>
                                                    <a href={"https://polygonscan.com/tx/" + a.txId} target="blank" >
                                                        View
                                                    </a>
                                                </td>
                                                <td className='linkshr text-end'>
                                                    <span style={{fontSize:"13px"}}>{a.eventsName}</span></td>
                                            </tr>)
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Treeviewdetail