export const nftAddress = {
    1: "0x09F0A5800c455F6A3215F4Ac237fedB34b57ca78",
    2: "0x560ABDf7D7112ab2fD2fEae24aA19aD896F88ECE",
    3: "0x2550c1A24cF45Dab0A644dcE25B0F0648b9482Df",
    4: "0xffCA7fdccB7350677aef0A41EC6365eeccd17E6c",
    5: "0xE88714Ce1AB617b18267aC838f57baf2773Cedff",
    6: "0x1216f95950815949574B1307B331816C025B28eC",
    7: "0x35C559f281b86980310Ea798E901cbc901B79F88",
    8: "0xC9515dcfc7Aeee8F1f3Db820e12fB4cC2F5744f7",
    9: "0xF43135EE6F03f1261Eaf9D6B84fCA28947b1d5c6",
    10: "0x6d8083BF8F9EA4E3b3A5EEfaC326d20753A90644",
    11: "0x403C57B8aA918803b42D325bBb0CF1a656954F7B",
    12: "0xF1E1Cf25166CD76A8B75A317E695B70802E9ff39",
    13: "0xe24a70e8Fc0C88Ff2dDee92Bd1f67824Da4753ae",
    14: "0x9828F6c178f8a62C914207ED849aaaa21713648D",
    15: "0xb8c650f82d00Fef2f6EeC7A860Ed6c808765dc92"
}