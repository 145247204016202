import { constants, ethers } from "ethers"
import { formatNumber, shortAddress } from "."
import { showError, showFulfill, showPromise } from "./pageHelpers"

export const _registerXgold = async (contractInst, upline, v, r, s, signature, messageHash) => {
    try {
        console.debug("message hash", messageHash, "signature", signature, "upline", upline, v, r, s)
        let txn = await contractInst.regUser(upline, messageHash, v, r, s, signature)

        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        await txn.wait()
        showFulfill(`Registered Successfully`, 'Transaction Confirmed')
        return true
    } catch (err) {
        console.log(err)
        showError(err.reason ? err.reason : (err.data ? err.data.message : err.message))
        return false
    }
}

export const _approve = async (tokenInst, spender) => {
    try {
        let txn = await tokenInst.increaseAllowance(spender, ethers.constants.MaxUint256)
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        await txn.wait()
        showFulfill(`Successfully approved USDT for registration!`, 'Transaction Confirmed')
        return txn
    } catch (err) {
        showError(err.reason ? err.reason : (err.data ? err.data.message : err.message))
        return err;
    }
}

export const _claim = async (stakeInstance) => {
    try {
        let txn = await stakeInstance.withdrawOron()
        showFulfill(`Transaction was submitted with txn hash: ${shortAddress(txn.hash)}`)
        await txn.wait()
        showFulfill(`Successfully claimed!`, 'Transaction Confirmed')
    } catch (err) {
        showError(err.reason ? err.reason : (err.data ? err.data.message : err.message))
    }
}
